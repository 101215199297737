<template>
  <div class="u-px">
    <div ref="mAuthorWrapper" class="c-embed__frame">
      <iframe
        class="c-mauthor-frame"
        name="mAuthorFrame"
        ref="mAuthorFrame"
        :src="mauthorSrc"
        tabindex="0"
        allowfullscreen="allowfullscreen"
        allow="geolocation *; microphone *; camera *; midi *; encrypted-media *"
      ></iframe>
    </div>
    <ContentLoader v-if="!mAuthorLoaded" height="160" width="100%">
      <rect x="0" y="0" :width="contentWidth" :height="contentHeight"></rect>
    </ContentLoader>
  </div>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'
import { computed, ref, onBeforeUnmount, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useBreadcrumbs } from '@/core'
import auth from '@/auth'

export default {
  name: 'EmbedExercise',

  components: {
    ContentLoader,
  },

  props: {
    item: Object,
    data: Object,
  },

  setup(props) {
    const mAuthorLoaded = ref(false)
    const mAuthorFrame = ref(null)
    const mAuthorWrapper = ref(null)
    const contentWidth = ref(1160)
    const contentHeight = ref(652)
    const router = useRouter()
    const { breadcrumbs } = useBreadcrumbs()
    const mauthorSrc = computed(() => {
      return `${process.env.VUE_APP_MAUTHOR_URL}?data=${props.data.content.embedId}&gateway=${process.env.VUE_APP_COSS_URL}&token=${auth.token}`
    })

    onMounted(() => {
      window.addEventListener('message', handleMessage)
      window.addEventListener('resize', screenResiseHandler)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('message', handleMessage)
      window.removeEventListener('resize', screenResiseHandler)
    })

    function handleMessage(event) {
      if (!event.data || event.origin?.indexOf('h5p') > -1) return
      const { eventType, eventData } = destructureMessage(event)

      switch (eventType) {
        case 'RESIZE':
          resizeFrame(eventData)
          break
        case 'PLAYER_READY':
          restoreLessonState()
          break
        case 'PLAYER_CLOSE':
          if (breadcrumbs.value[breadcrumbs.value.length - 2]) {
            router.push(breadcrumbs.value[breadcrumbs.value.length - 2].to)
          }
          break
        default:
          break
      }
    }

    async function restoreLessonState() {
      sendMessageToFrame(`LESSON_STATE:${JSON.stringify(null)}`)
    }

    function resizeFrame() {
      mAuthorLoaded.value = true
      mAuthorFrame.value.style.width = `${contentWidth.value}px`
      mAuthorFrame.value.style.height = `${contentHeight.value}px`

      setTimeout(screenResiseHandler, 1)
    }

    function screenResiseHandler() {
      if (mAuthorWrapper.value) {
        const scale = Math.min(
          1,
          mAuthorWrapper.value.offsetWidth / contentWidth.value,
        )
        mAuthorFrame.value.style.transform = `scale(${scale})`
      }
    }

    function destructureMessage(event) {
      const eventTypeMarker =
        event.data?.indexOf(':') > -1
          ? event.data?.indexOf(':')
          : event.data?.length

      const eventType = event.data.substr(0, eventTypeMarker)
      const eventData = event.data.substr(eventTypeMarker + 1)

      return {
        eventType,
        eventData,
      }
    }

    function sendMessageToFrame(message) {
      window.frames['mAuthorFrame'].postMessage(
        message,
        `${process.env.VUE_APP_MAUTHOR_URL}`,
      )
    }

    return {
      mauthorSrc,
      mAuthorFrame,
      mAuthorWrapper,
      mAuthorLoaded,
      contentWidth,
      contentHeight,
    }
  },
}
</script>
<style lang="scss" scoped>
.c-embed__container,
.c-embed__frame {
  position: relative;
  text-align: center;
  overflow: hidden;

  .c-mauthor-frame {
    border: none;
    width: 0;
    height: 0;
    overflow: hidden;
    transform: scale(1);
    transform-origin: left top;
  }
}
</style>
